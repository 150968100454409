import CustomImage from '@components/shared/customImage';
import { useViewport } from '@store/ViewPort';
import React from 'react';
import classes from './footer-desktop.module.scss';

const FooterDesktop: React.FC = () => {
  const { width } = useViewport();
  const Width = width !== undefined ? width : 0;
  return (
    <div className={classes.footer_desktop} style={{ width: Width - 40 }}>
      <div className={classes.footer_desktop_content}>
        <div className={classes.footer_desktop_content_detail}>
          <p className={classes.head}>Get in touch</p>
          <p className={classes.sub}>
            Email: <a href="mailto:support@wellproz.com">support@wellproz.com</a>
          </p>
          <p className={classes.sub}>
            Phone:
            <a href="tel:866-364-0963"> 866-364-0963</a>
          </p>
        </div>
        <div className={classes.footer_desktop_content_detail}>
          <p className={classes.head}>Learn More</p>
          <p className={classes.sub}>
            Explore our{' '}
            <a
              href="https://wellproz.zendesk.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={classes.sub_boldtext}>Help Center</span>{' '}
            </a>
          </p>
          <p className={classes.sub}>
            Join the{' '}
            <a
              href="https://www.facebook.com/groups/wellproz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={classes.sub_boldtext}>Facebook Group</span>
            </a>
          </p>
          <p className={classes.sub}>
            Read our{' '}
            <a
              href="https://blog.vibrant-wellness.com/wellproz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={classes.sub_boldtext}>Insights</span>
            </a>
          </p>
        </div>
        <div className={classes.footer_desktop_content_detail}>
          <a
            href="https://www.capterra.com/reviews/254874/Well-ProZ?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomImage
              isStaticSrc={false}
              src={'images/home/footer/capterra.svg'}
              alt="revenue"
              height="102px"
              width="312px"
            />
          </a>
        </div>
      </div>
      <div className={classes.footer_desktop_content}>
        <div className={classes.footer_desktop_logos_media}>
          <div className={classes.facebook}>
            <a
              href="https://www.facebook.com/wellproz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomImage
                isStaticSrc={false}
                src={'images/home/footer/fb.svg'}
                alt="revenue"
                height="50px"
                width="50px"
              />
            </a>
          </div>

          <div className={classes.linkedIn}>
            <a
              href="https://www.linkedin.com/company/well-proz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomImage
                isStaticSrc={false}
                src={'images/home/footer/linked-in.svg'}
                alt="revenue"
                height="50px"
                width="50px"
              />
            </a>
          </div>
          <div className={classes.youtube}>
            <a
              href="https://www.youtube.com/channel/UCZYjwb0fpl80RE6qfgAshEg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomImage
                isStaticSrc={false}
                src={'images/home/footer/youtube.svg'}
                alt="revenue"
                height="50px"
                width="50px"
              />
            </a>
          </div>
          <div className={classes.instagram}>
            <a
              href="https://www.instagram.com/well_proz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomImage
                isStaticSrc={false}
                src={'images/home/footer/instagram.svg'}
                alt="insta"
                height="50px"
                width="50px"
              />
            </a>
          </div>
        </div>
        <div className={classes.footer_desktop_logos_media2}>
          <a
            href="https://www.vibrant-wellness.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomImage
              isStaticSrc={false}
              src={'images/home/footer/vibrant.svg'}
              alt="revenue"
              height="40px"
              width="387px"
              className={classes.vibrant}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterDesktop;
